/* JL
font-weights:
400 regular
600 semibold
700 bold
*/

/* Colors
#123225 rgb(0,32,91)    primary blue (navy)
#123225 rgb(181,189,0)  green
#123225 rgb(0,181,226)  bright blue (links)
#e03c31 rgb(224,60,49)  red
#ffc658 rgb(255,198,88) yellow-orange
*/

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: "Open Sans", sans-serif;
}

p {
    margin-bottom: 20px;
}

.app-wrapper {
    max-height: 100vh;
    height: 100vh;
}

a.link-button,
.link-button {
    background: none !important;
    color: #123225;
    border: none;
    padding: 0 !important;
    font: inherit;
    font-size: 15px;
    cursor: pointer;
    border-radius: 0;
}
a.link-button:hover,
.link-button:hover {
    text-decoration: underline;
}
a.link-button:disabled,
.link-button:disabled {
    color: #ccc;
}

/* JL: hack for "a" tag issues */
a.link-color,
.link-color {
    color: #123225;
    cursor: pointer;
}

a.link-color:hover,
.link-color:hover {
    text-decoration: underline;
}

h1.screen-title {
    color: #123225;
    text-align: center;
    margin-bottom: 10px;
}

h1.screen-title.terms {
    flex: 1;
    margin-right: 110px;
}

h1.screen-title.terms.mobile {
    margin-right: 20px;
}

h2 {
    color: #123225;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 15px;
}

/* Used on Login screen */
h2.screen-title {
    font-size: 20px;
    text-transform: none;
    margin-bottom: 10px;
}

h3 {
    font-size: 16px;
}

h5 {
    font-size: 1.2em;
    line-height: 1em;
    margin-bottom: 15px;
    color: #123225;
}

/* Styles required by react-simple-dropdown */

.dropdown {
    display: inline-block;
}

.dropdown__content {
    display: none;
    position: absolute;
    z-index: 100;
}

.dropdown--active .dropdown__content {
    display: block;
}

/* End Styles required by react-simple-dropdown */

.modal {
    position: fixed;
    border-radius: 6px;
    top: 30px;
    left: 50%;
    transform: translate(-50%, 0);
    padding: 10px 20px 20px 20px;
    width: 700px;
    max-height: calc(100% - 100px);
    background-color: rgba(255, 255, 255, 1);
    outline: none;
    overflow: hidden;
    z-index: 100;
    border: 1px solid rgb(204, 204, 204);
}

.ReactModal__Overlay {
    z-index: 2;
}

.popupbox-wrapper {
    max-height: 90%;
}

.popupbox-content {
    overflow: auto;
}

input[type="radio"] {
    margin: 3px 3px 0 5px;
}

select {
    height: 30px;
    border-radius: 5px;
}

.margin-top {
    margin-top: 10px;
}

.margin-bottom {
    margin-bottom: 10px;
}
.MuiButtonBase-root {
    background-color: #123225;
}

.programEventInviteExportButton {
    color: #123225 !important;
    height: 25px !important;
    width: 100px !important;
    text-align: center !important;
    text-transform: uppercase !important;
    justify-content: center !important;
    border-color: #123225 !important;
    font-weight: 600 !important;
    padding: 18px !important;
    margin: 0px !important;
}

@media only screen and (max-width: 600px) {
    h1.screen-title {
        font-size: 20px;
    }

    h2.screen-title {
        font-size: 16px;
    }

    .accept-invite__logo-container .accept-invite__logo {
        width: 300px;
    }
}