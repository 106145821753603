.login-ui {
    min-height: 100%;
    flex: 1 1 0%;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    flex-direction: column;
    /* background-color: #f8f6e3; */
}
.login-ui .login-details {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
}
.login-ui .login-forgot-pwd-name {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 20px;
}

.login-ui .login-details .forgot-pwd {
    text-align: right !important;
}
.login-ui .login-details input[type="checkbox"] {
    height: 13px;
    margin-bottom: 20px;
}
/* JL: set for now. inconsistent widths so need a set hack until we encounter a longer language option */
/* .login-ui select {
    width: 250px;
} */

.login-btn {
    min-height: 60px;
}
.login-create-account-section {
    /* width: 500px; */
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
}
.login-create-account {
    margin-bottom: 10px;
    /* width: 500px; */
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
}
.login-staff-login-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.logo {
    background: url("../../images/OWL_Logos_FInal-03.png") no-repeat center top;
    background-size: 100%;
    max-height: 170px;
    height: 170px;
	min-height:170px;
	max-width: 317px;
}

@media screen and (max-width: 600px) {

    .login-detail h2 {
        margin-top: 10px;
    }
}
