.scholarship-reports-summary .cash {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-width: 350px;
  max-width: 350px;
  min-height: 170px;
  border-style: solid;
  border-color: black;
  border-width: 1px;
  padding: 10px;
  padding-bottom: 0px;
  cursor: pointer;
}

.scholarship-reports-summary .cash-active {
  background-color: #123225;
  color: white;
}

.scholarship-reports-summary .cash-active span {
  color: white;
}

.scholarship-reports-summary .cash .row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: stretch;
  margin-bottom: 5px;
}

.scholarship-reports-summary .cash .row-spacer {
  min-height: 25px;
  max-height: 25px;
}

.scholarship-reports-summary .cash .row .spacer {
  flex: 1;
}

.scholarship-reports-summary .cash .line {
  min-height: 2px;
  max-height: 2px;
  background-color: #F5E8D1;
  margin-top: 5px;
  margin-bottom: 5px;
}
