.scholarship-refund-form {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    background-color: rgb(238, 240, 197);
    padding: 10px;
    border-radius: 10px;
}
.scholarship-refund-form span {
    font-weight: bold;
}
.scholarship-refund-form div {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-around;
}

.scholarship-refund-form .row {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    min-width: 300px;
}
.scholarship-refund-form .spacer {
    flex: 1;
}
.scholarship-refund-form button {
    min-width: 100px;
    min-height: 100px;
    margin-left: 10px;
}
.scholarship-refund-form .check-number {
    max-width: 70px;
    margin-right: 100px;
}
.scholarship-refund-form .check-number-label {
    margin-left: -60px;
}
.scholarship-refund-form .rdt {
    max-width: 100px;
}
