.scholarship-refund-list {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}
.scholarship-refund-list .request-list-body {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  min-height: 150px;
  max-height: 150px;
  overflow-y: auto;
}
.scholarship-refund-list .request-list-body .request-list-row {
  background-color: transparent;
  border: none;
  max-height: 35px;
  min-height: 35px;
  outline: none;
  cursor: pointer;
  max-width: 100%;
}
.scholarship-refund-list .request-list-body .request-list-row div {
  max-height: 35px;
  min-height: 35px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.scholarship-refund-list button {
  max-width: 300px;
}

.scholarship-refund-list .request-list-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-bottom-color: #123225;
}

.scholarship-refund-list .request-list-column {
  min-width: 200px;
  max-width: 400px;
}
.request-list-body button.request-list-row-selected {
  background-color: rgb(238, 240, 197) !important;
}
