.staff-layout .ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
    background: #fdf8bb;
    cursor: pointer;
}

.staff-layout .ReactTable {
    border: none;
}

.staff-layout .ReactTable .rt-tbody {
    border: 1px solid black;
}

.staff-layout .ReactTable .rt-thead .rt-tr {
    text-align: left;
    font-weight: bold;
}

.staff-layout .ReactTable .rt-thead .rt-th {
    border-right: none;
}

.staff-layout .ReactTable .rt-tbody .rt-tr-group {
    border-bottom-color: black;
}

.school-table {
    margin-bottom: 40px;
}

.staff-layout .filter-area {
    display: flex;
    justify-content: space-between;
}

.staff-layout .filter-area button {
    background-color: #123225;
    border: none;
    color: white;
    padding: 5px 10px;
    cursor: pointer;
    width: 240px;
}

.staff-layout .filter-area button:hover {
    opacity: 0.75;
}
h3 {
    color: #E2A331 !important;
}
/*span {
	color: #E2A331 ;
}*/