.btn {
    padding: 6px 25px;
    border: none;
    background-color: #123225;
    color: #ffffff;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    font-size: 1em;
    border-radius: 5px;
    text-transform: uppercase;
}

.btn-reverse {
    background-color: #123225 !important;
    color: #ffffff !important;
}
.btn:hover {
    cursor: pointer;
    opacity: 0.75;
}

.btn-disabled,
.btn:disabled {
    background-color: lightgrey;
}

.btn-disabled:hover,
.btn:disabled:hover {
    cursor: not-allowed;
    opacity: 1;
}
