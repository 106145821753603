.admin-school-edit {
    flex: 1;
    padding: 20px;
}
.admin-school-edit h1 {
    color: #123225;
}
.admin-school-edit .form-radio-div {
    width: 160px;
}
.admin-school-edit-buttons a {
    margin-left: 20px;
}
.admin-school-edit-row {
    flex: 1;
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
}
.admin-school-edit-row div {
    flex: 1;
}
.is-active-field {
    width: 50%;
}
.admin-school-edit-row div div:first-of-type {
    margin-right: 10px;
}
.admin-school-edit-input .form-inner-div {
    max-width: 500px;
}
.admin-school-edit-address-label {
    flex: 1;
    color: #123225;
    font-size: 20px;
}
.admin-school-edit textarea {
    height: 100px;
}

.admin-school-edit-line {
    border-top-color: #b5bd02;
    border-top-style: solid;
    border-top-width: 2px;
    margin-top: 10px;
    padding-top: 10px;
}
