.scholarship-year-picker {
  position: relative;
  margin-right: 50px;
  margin-bottom: 20px;
  padding: 5px 15px;
  background-color: #123225;
  color: white;
  font-weight: bold;
  outline: none;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

/* Make dropdown look good on admin programs screen */
.admin .scholarship-year-picker {
  border-radius: 10px;
}

.scholarship-year-picker:focus {
  box-shadow: 0 0 3pt 2pt #4d90fe;
}

.scholarship-year-picker > span {
  color: white;
}

.scholarship-year-picker--can-open {
  cursor: pointer;
}

.scholarship-year-picker--open,
.admin .scholarship-year-picker--open {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.scholarship-year-picker__down {
  color: white;
  margin-left: 10px;
}

.scholarship-year-picker__list {
  padding: 5px 15px;
  background-color: #123225;
  position: absolute;
  left: 0;
  margin-top: 5px;
  max-height: 200px;
  overflow: auto;
  z-index: 100;
  width: 143px;
  display: none;
}

.scholarship-year-picker--open .scholarship-year-picker__list {
  display: block;
}

.scholarship-year-picker__list ul {
  list-style-type: none;
}

.scholarship-year-picker__list li {
  padding: 5px 0;
}

.scholarship-year-picker__list li:hover {
  opacity: 0.75;
}
