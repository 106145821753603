.MuiTab-root.Mui-selected {
    background-color: #123225 !important;
}

.tabVisible {
    display: flex !important;
}

.tabHidden {
    display: none !important;
}

.profileActionButton {
    background-color: #123225 !important;
    color: white;
}
.profileActionButton .MuiButton-label {
    color: white !important;
}

.buttonContainer {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
}


.buttonContainer > * {
    margin: 0 10px;
}


.container {
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    height: 75vh;
    width: 90%;
}

.content {
    flex: 1;
    overflow-y: auto;
    overflow-x: auto;
    padding: 20px;
}
.formContent {
    flex: 1;
    overflow-y: auto;
    overflow-x: auto;
    padding: 20px;
}