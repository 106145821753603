.complete-reject {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
    margin-left: 20px;
}

.complete-reject button:disabled {
    opacity: 0.5;
}

.complete-reject .application-info-container {
    text-align:left;
    background-color: #F5E8D1;
    padding: 20px;
}

.complete-reject .reject-container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background-color: #F5E8D1;
    padding: 5px 20px;
}
.complete-reject .reject-container button.btn {

    margin-top:10px;
}
.complete-reject .reject-container button.btn.resubmit {
    background-color: #e2a331;
}

.complete-reject .submit-status {
    font-style: italic;
    font-size: 12px;
    margin-top: 5px;
}

.complete-reject .preferred-language {
    font-weight: bold;
    font-size: 13px;
}

.complete-reject .reject-container > div {
    margin-top: 10px;
}

.complete-reject .complete-container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 20px;
    font-size: 25px;
    background-color: #ffc658;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.complete-reject .complete-container.disabled {
    opacity: 0.5;
}

.complete-reject .notes-container {
    display: flex;
    flex-direction: column;
    margin: 20px 0;
    align-items: stretch;
    background-color: #2c6648;
    color: #fff;
    padding: 20px;
    border-radius: 10px;
}

.complete-reject .notes-container > span {
    font-weight: bold;
}

.complete-reject .notes-container > textarea {
    height: 200px;
    margin: 15px 0;
    padding: 10px;
}

.complete-reject .notes-buttons {
    margin-top: 5px;
}

.complete-reject .notes-buttons .btn {
    background-color: #f5e8d1;
    color: #2c6648;
}

.complete-reject .notes-buttons > .link-button {
    margin-left: 10px;
    color: #f5e8d1;
}

.complete-reject .reject-student-container {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    align-items: center;
}

.complete-reject .orientation-container {
    display: flex;
    flex-direction: column;
    background-color: #F5E8D1;
    padding: 20px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.complete-reject .orientation-container.disabled {
    opacity: 0.5;
}

.complete-reject .orientation-container > span {
    font-weight: bold;
    line-height: 2em;
}

.complete-reject .orientation-container > button {
    align-self: center;
    margin: 10px 0;
}

.complete-reject > button:first-of-type {
    margin-left: 80px;
    margin-right: 80px;
}

.complete-reject .event-list {
    background-color: white;
    height: 75px;
    overflow: auto;
    border: 1px solid #9b9b9b;
    border-radius: 5px;
    padding: 10px;
}

.complete-reject .event-item {
    margin-bottom: 5px;
}

.complete-reject .event-date {
    width: 70px;
    display: inline-block;
}

.complete-reject input {
    /* height: 100px; */
}
