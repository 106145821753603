.admin-school-header {
    margin: 5px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.admin-school-header button {
    height: 40px;
    background-color: #123225;
    color: white;
    padding: 5px 10px;
    border: none;
}
.admin-school-header button span {
    margin-right: 5px;
    color: white;
}
