.settings button.school {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    margin: 5px;
    width: 350px;
    height: 45px;
}

.settings button.school.selected {
    background-color: #123225;
    cursor: pointer;
    color: #ffffff;
    border: none;
}

.settings button.school.unselected {
    color: #123225;
    border-style: solid;
    border-width: 1px;
    border-color: #123225;
    cursor: pointer;
    background-color: white;
}
