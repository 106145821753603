.staff-layout .ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
    background: #fdf8bb;
    cursor: pointer;
}

.staff-layout .ReactTable {
    border: none;
}

.staff-layout .ReactTable .rt-tbody {
    border: 1px solid black;
}

.staff-layout .ReactTable .rt-thead .rt-tr {
    text-align: left;
    font-weight: bold;
}

.staff-layout .ReactTable .rt-thead .rt-th {
    border-right: none;
}

.staff-layout .ReactTable .rt-tbody .rt-tr-group {
    border-bottom-color: black;
}

.school-table {
    margin-bottom: 40px;
}

.staff-layout .filter-area {
    display: flex;
    flex-direction: row-reverse;
}

.staff-layout .filter-area button {
    background-color: #123225;
    border: none;
    color: white;
    padding: 5px 10px;
    cursor: pointer;
    width: 240px;
}

.staff-layout .filter-area button:hover {
    opacity: 0.75;
}
.complete-row-header {
    text-align: center;
}
.complete-school-table .rt-td div.complete-column {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.complete-school-table .print-roster {
    background-color: #123225;
    border: none;
    color: white;
    padding: 3px 6px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    min-height: 30px;
    min-width: 40px;
    cursor: pointer;
}
.complete-school-table-row-24hours {
    font-weight: bold;
}
