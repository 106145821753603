.quota-stats {
    padding: 10px;
    background-color: #F5E8D1;
    font-size: 0.9em;
}

.quota-stats .quota-stats-header {
    color: #123225;
    font-size: 1.5em;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.quota-stats .quota-stats-header input {
    width: 80px;
    margin-bottom: 0;
    font-size: 1em;
    text-align: center;
}

.quota-stats .quota-stats-date-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.quota-stats .quota-stats-date {
    font-style: italic;
    font-size: 0.9em;
}

.quota-stats .quota-stats-top-area {
    display: flex;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 2px solid #123225;
}

.quota-stats .quota-stats-top-area > div:nth-child(1),
.quota-stats .quota-stats-top-area > div:nth-child(2) {
    border-right: 1px solid #123225;
    padding-right: 20px;
}

.quota-stats .quota-stats-top-area > div:nth-child(2),
.quota-stats .quota-stats-top-area > div:nth-child(3) {
    padding-left: 20px;
}

.quota-stats ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}
