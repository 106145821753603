.admin-scholarship-cash {
    display: flex;
    flex-direction: row;
    margin-left: 400px;
}
.admin-scholarship-cash-labels {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
}

.admin-scholarship-cash-labels span {
    padding-bottom: 20px;
    font-weight: bold;
}
.admin-scholarship-cash-labels span:first-child {
    padding-bottom: 25px;
    font-weight: bold;
}

.admin-scholarship-cash-amounts {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 20px;
}
.admin-scholarship-cash-amounts input {
    max-width: 60px;
    max-height: 30px;
    margin-bottom: 20px;
}
.admin-scholarship-cash-amounts span {
    padding-bottom: 20px;
}
.admin-scholarship-cash-amounts span:first-child {
    padding-bottom: 0px;
    font-weight: bold;
}
