.application-status {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: 10px;
}

.application-status .appstatus-cell-1 {
    width: 40%;
}

.application-status .appstatus-cell-2 {
    width: 10%;
}

.application-status .appstatus-cell-3 {
    width: 40%;
}

.application-status .appstatus-cell-4 {
    width: 10%;
}
