.scholarship-recieve-refund-payments {
  margin-bottom: 20px;
}

.scholarship-recieve-refund-payments .rt-tbody .rt-td:hover {
  cursor: pointer;
}

.scholarship-recieve-refund-payments .fa-exclamation-circle {
  color: red;
}

.refund-info {
  background-color: #F5E8D1;
  border-radius: 10px;
  padding: 15px 25px;
  display: flex;
  height: 50%;
}

.refund-info .data-container {
  display: flex;
  flex-direction: column;
}

.refund-info .data-container .top-row {
  display: flex;
}

.refund-info .data-container .top-row .payment-field,
.refund-info .data-container .top-row .payment-total {
  margin-right: 20px;
}

.refund-info .data-container .top-row .payment-field .form-input-error {
  max-width: 100px;
}

.refund-info .data-container .top-row .payment-field input,
.refund-info .data-container .top-row .payment-total input {
  max-width: 100px;
}

.refund-info .data-container .bottom-row {
  margin-right: 20px;
}

.refund-info .button-container {
  display: flex;
  align-items: center;
}

.refund-info .button-container .btn {
  padding-top: 40px;
  padding-bottom: 40px;
}
