.admin-scholarships-programs-btns .link-button {
    margin-left: 20px;
}
.admin-scholarships-programs-header {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.admin-scholarships-programs-header .scholarship-year-picker {
    margin-bottom: 5px;
}
