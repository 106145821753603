.App {
    /* max-height: 100vh;
    height: 100vh; */
    width: 100%;
    height: 100%;
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
    background-color: #F5E8D1;
}
.App.auth {
    background-color: #ffffff;
}

.middle-container {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: stretch;
    overflow: hidden;
}

.Workarea {
    width: 100%;
    overflow: auto;
    margin-bottom: 75px;
}

.Workarea.auth {
    background-color: #ffffff;
}

@media only screen and (max-width: 600px) {
    .Workarea {
        margin-bottom: 0;
    }
}

.Loading {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: black;
    opacity: 0.2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 2;
}

.line-scale {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.line-scale > div {
    height: 100px;
    width: 20px;
}

.MuiTypography-colorPrimary.show-instructions-link {
    color: #3cc6e8;
}

.instructions {
    background-color: #F5E8D1;
    border-width: 1px;
    border-color: black;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    padding: 10px 20px;
    font-size: 0.9em;
    width: 325px;
    margin-left: 10px;
    margin-right: -10px;
}

.instructions.mobile {
    width: 100%;
    border-radius: 0;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 10px;
}

.instruction-text {
    display: block;
    margin-bottom: 10px;
}
.contact-name {
    font-weight: 600;
}
.contact-title {
    font-style: italic;
}

.back-button {
    max-width: 154px;
}
.submit-button {
    max-width: 111px;
}

button {
    border-radius: 5px;
}
input {
    border-radius: 5px;
}

input[type="text"]:not(.MuiInputBase-input),
input[type="password"]:not(.MuiInputBase-input) {
    padding: 5px;
    border: 1px solid #afafaf;
}

input[type="text"]:not(.MuiInputBase-input)::placeholder,
input[type="password"]:not(.MuiInputBase-input)::placeholder {
    font-size: 14px;
}

label {
    color: #e2a331;
}

h1,
h2,
h4,
h5 {
    color: #F5E8D1;
}
.Mui-disabled span {
    color: #ababab;
}
.text-color {
    color: #123225;
}
.hr-line {
    height: 1px;
    max-height: 1px;
    background-color: #123225;
}
.hr-line-medium {
    margin-bottom: 7px;
    height: 4px;
    max-height: 4px;
    background-color: #123225;
}
.disable-div {
    background-color: white;
    opacity: 0.4;
    pointer-events: none;
}
.sk-fade-in div {
    height: 200px;
    width: 50px;
}
a {
    /* color: #123225 !important; */
    text-decoration: none;
    cursor: pointer;
}
.link-button {
    background: none !important;
    color: inherit;
    border: none;
    padding: 0 !important;
    font: inherit;
    /*border is optional*/
    border-bottom: 1px solid #444;
    cursor: pointer;
}
/*
a:hover {
    text-decoration: underline;
}
*/

.ReactTable.financials-table {
    border: none;
    font-size: 14px;
}

.ReactTable.financials-table .rt-thead {
    font-weight: 600;
}

.ReactTable.financials-table .rt-thead.-header {
    box-shadow: none;
    border-bottom: 2px solid #123225;
}

.ReactTable.financials-table .rt-thead .rt-th,
.ReactTable.financials-table .rt-thead .rt-td,
.ReactTable.financials-table .rt-tbody .rt-td {
    border-right: none;
}

.ReactTable.financials-table .rt-thead .rt-tr {
    text-align: left;
}

.ReactTable .rt-noData {
    box-shadow: 2px 2px 5px grey;
}

.negative-value {
    color: #ff0000;
}

ul {
    list-style-position: inside;
}

button {
    transition: 0.5s ease;
}

button:hover {
    opacity: 0.75;
}



@media only screen and (max-width: 600px) {
    .login-new-account-instructions {
        margin: 0;
        width: unset;
    }
    .MuiTypography-colorPrimary.show-instructions-link {
        margin-bottom: 10px;
    }
}
