.contracts-container {
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
}

.contracts-container .content {
    flex: 1;
}

.contracts-container .signatures {
    width: 100%;
    margin-bottom: 10px;
}

.contracts-container .signatures .form-input {
    border: 2px solid #afafaf;
    margin-bottom: 0;
    font-size: 20px;
    outline: unset;
    line-height: 1em;
    height: 36px;
}

.contracts-container .signatures td:not(:last-child) .form-input {
    margin-right: 20px;
}

.contracts-container .signatures .form-input:focus {
    border: 2px solid #123225;
}

.contracts-container .signatures .signature .form-input {
    font-family: Parisienne;
    text-indent: 5px;
}

.contracts-container .signatures .date {
    border-bottom: 2px solid #afafaf;
    height: 36px;
    display: flex;
    align-items: flex-end;
}

.contracts-container .signatures td div:nth-child(2) {
    font-weight: 700;
    margin-bottom: 10px;
}

.contracts-container .extra-margin {
    margin: 40px 0;
}

.contracts-container .acknowledge {
    margin: 30px 0;
}

.contracts-container .acknowledge .checkbox-label-wrapper {
    align-items: flex-start;
}

.contracts-container .acknowledge input[type="checkbox"] {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.contracts-container .acknowledge .acknowledge-input-wrapper {
    display: flex;
    flex-direction: column;
    background-color: rgba(255, 255, 0, 0.8);
    padding: 10px;
    align-items: center;
    justify-content: center;
}

.contracts-container .acknowledge .form-input-error {
    margin-left: 30px;
}

.contracts-container .signature .form-input {
    background-color: rgba(255, 255, 0, 0.8);
}

.link-button span {
    background: none !important;
    border: none;
    padding: 0 !important;
    /*optional*/
    font-family: arial, sans-serif;
    /*input has OS specific font-family*/
    color: #123225;
    text-decoration: underline;
    cursor: pointer;
}

/*.contracts-form {
    color: #F5E8D1;
}*/
.contracts-form .form-header {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000000 !important;
}
.contracts-form .wrapper-col {
    width: 100%;
    display: flex;
    flex-direction: column;
}
.contracts-form .title {
    text-align: center;
}
.contracts-form .section {
    display: flex;
    flex-direction: column;
    margin: 0 0 20px;
	color: #000000 !important;
}
.contracts-form .section-title {
    font-weight: 700;
}
.contracts-form .title.set-width {
    width: 350px;
}
.contracts-form ul.list {
    margin: 0;
}
.contracts-form p.content {
    margin: 10px 0;
    color: #000000;
}
table.signature-table {
    width: 100%;
}
table.signature-table td {
    vertical-align: bottom;
}
table.signature-table tr td:not(:last-child) .content-line {
    margin-right: 30px;
}
table.signature-table .label-cell {
    font-size: 12px;
    font-weight: 700;
    padding-bottom: 20px;
}
table.signature-table .content-line {
    border-top: 1px solid #000;
}
table.signature-table .cursive {
    font-family: Parisienne;
}
.contracts-form h1 {
		color: black !important;
		}

@media only screen and (max-width: 600px) {
    .contracts-container {
        display: block;
    }
    .form-header img {
        width: 150px;
    }
    .contracts-container table,
    .contracts-container thead,
    .contracts-container tbody,
    .contracts-container th,
    .contracts-container td,
    .contracts-container tr {
        display: block;
    }
	.contracts-container h1 {
		color: black;
	}
	.contracts-container .section span {
		color: black;
	}

}
