.login-term {
    flex: 1 0 0%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    margin-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
}
.login-term-parent {
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.login-term-bottom {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
    margin-top: 20px;
    max-width: 400px;
}
.login-term-bottom .radio-wrapper {
    align-self: center;
}
.login-term-inputs-wrapper {
    flex: 1 0 0%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.login-term-inputs {
    flex: 1 0 0%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
}
.login-term-input {
    margin-bottom: 10px;
}
.login-term-back-button {
    min-height: 40px;
    min-width: 110px;
}

.login-term-back-button .arrow {
    color: #ffffff;
}

.login-term-back-button.mobile {
    padding: 6px;
    max-width: 50px;
    min-width: unset;
    margin-bottom: 10px;
}

.login-term-button {
    margin-top: 10px;
    margin-bottom: 10px;
    min-height: 60px;
}
.login-term-print-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
}
.login-term-print-button > .fa-print {
    color: #123225;
    cursor: pointer;
}
.login-term-print-button:hover {
    opacity: 0.7;
}
.login-term__text {
    margin-left: auto;
    margin-right: auto;
    max-width: 915px;
    margin-top: 15px;
    background-color: white;
    padding: 15px;
    border: solid 1px gray;
    border-radius: 5px;
}

@media print {
    .Workarea {
        overflow: visible !important;
    }

    .no-print {
        display: none;
    }
}
