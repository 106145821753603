.admin-user-edit {
    flex: 1 0 0%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
    padding: 20px;
    max-width: 500px;
}

.admin-user-edit .parent-note {
    font-weight: bold;
    margin-bottom: 20px;
}

.admin-user-edit span {
    display: block;
    font-weight: bold;
    margin-bottom: 10px;
}
.admin-user-edit .form-radio-div {
    width: 160px;
}
.admin-user-edit-buttons a {
    margin-left: 20px;
}

.admin-user-edit .user-row {
    display: flex;
    justify-content: space-between;
}

.admin-user-edit .user-row > *:nth-child(1),
.admin-user-edit .user-row > *:nth-child(3) {
    flex: 2;
}

.admin-user-edit .user-row > *:nth-child(2) {
    flex: 1;
}

.admin-user-edit .password-help {
    font-style: italic;
    margin-bottom: 10px;
    font-size: 14px;
    color: #3cc6e8;
}

.studentFieldValue {
    font-weight: bold;
}

.chip {
    background: #123225 !important;
}

.directorCheckboxContainer {
    display: inline;

}
 div.directorCheckboxContainer > label > span.MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label {
    color: #123225 !important;
    position: relative;
    top: -12px;
}
td.directorCheckboxContainer > label > span.MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label {
    color: #123225 !important;
    position: relative;
    top: -8px;
}

.searchBox {
    width: 100% !important;
}