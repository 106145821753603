.household-form-radio {
    justify-content: flex-start;
}
.household-form-left-row {
    display: flex;
    flex-direction: row;
}
.household-form-other-field {
    margin-left: 10px;
}

.household-form-left-container {
    flex: 1;
}
