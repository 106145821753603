.household-form-right-container {
    flex: 1;
    margin-left: 20px;
    margin-right: 20px;
}
.household-form-label-spacer {
    margin-top: 10px;
    margin-bottom: -10px;
}
.household-form-input-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: stretch;
}
.household-form--gender-radio {
    margin-top: 0px;
    padding-top: 15px;
    margin-right: 20px;
}
.household-form-school-list {
    margin-left: 20px;
    flex: 1;
}
.household-form-school-list option {
    max-width: 100px;
}
.household-form-right-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 20px;
}
.household-form-right-buttons a {
    margin-left: 10px;
}
.household-form-other-field {
    margin-top: -10px;
}

.disable-div.hide-div {
    display: none;
}
