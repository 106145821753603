.edit-cycle-modal .button-container {
    border-top: 1px solid #123225;
    margin-top: 20px;
    padding-top: 20px;
}

.edit-cycle-modal__warning {
    border: solid 1px #f44336;
    font-size: 14px;
    padding: 10px;
    color: #f44336;
    background-color: #fde4e2;
    margin-bottom: 10px;
    text-align: center;
}

.edit-cycle-modal .link-button {
    margin-left: 10px;
}

.edit-cycle-modal__table {
    margin-top: 10px;
    border-collapse: collapse;
    font-size: 14px;
}

.edit-cycle-modal__table thead {
    font-weight: 600;
    font-size: 14px;
}

.edit-cycle-modal__table td,
.edit-cycle-modal__table th {
    padding: 0 20px 0 10px;
    text-align: left;
}

.edit-cycle-modal__date {
    margin-right: 10px;
}

.chk-container {
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    font-size: 22px;
    user-select: none;
    height: 24px;
}

.chk-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.chk-container .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border: solid 1px #123225;
}

.chk-container:hover input ~ .checkmark {
    background-color: #ccc;
}

.chk-container input:checked ~ .checkmark {
    background-color: #123225;
    border: none;
}

.chk-container .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.chk-container input:checked ~ .checkmark:after {
    display: block;
}

.chk-container .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}
