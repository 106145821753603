.scholarship-reports-header {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.scholarship-reports-header a {
  display: block;
}

.scholarship-reports-header a div {
  background-color: white;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px #c8d1e3;
  color: #F5E8D1;
  margin-bottom: 5px;
  margin-right: 20px;
  border-radius: 10px;
  padding: 20px;
  padding-left: 30px;
  padding-right: 30px;
  font-weight: bold;
  transition: all 0.5s ease;
}

.scholarship-reports-header a div:hover {
  opacity: 0.75;
}

.scholarship-reports-header a.active-link div {
  background-color: #123225;
  color: white;
}
