.chkbox {
    background-color: #123225;
    border: none;
    color: white;
    padding: 3px 6px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    min-height: 30px;
    min-width: 40px;
}
.chkbox-unchecked {
    background-color: #123225;
    min-height: 20px;
    min-width: 30px;
}
.chkbox-pointer {
    cursor: pointer;
}
