.forgot-password {
    min-height: 100%;
    flex: 1 1 0%;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    flex-direction: column;
    /* background-color: #f8f6e3; */
}
.forgot-password .form-input {
    min-height: 30px;
}
.forgot-password-details {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    padding: 0 10px 10px;
    max-width: 600px;
}
.forgot-password-btns {
    flex: 1 0 0%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
}

@media only screen and (max-width: 600px) {
    .forgot-password-details {
        max-width: 370px;
    }
}
