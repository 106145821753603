.scholarship-certify-student-edit {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.scholarship-certify-student-edit-row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

    .scholarship-certify-student-edit-row div {
        min-width: 200px;
        margin-right: 20px;
    }

    .scholarship-certify-student-edit-row .phone-type {
        margin-left: 20px;
    }

    .scholarship-certify-student-edit-row.level-type {
        max-width: 200px;
        flex-direction: column;
    }

    .scholarship-certify-student-edit-row .household-form--gender-radio {
        min-height: 35px !important;
        margin-right: 20px !important;
    }

        .scholarship-certify-student-edit-row .household-form--gender-radio label {
            margin-right: 5px;
        }

.row-space-between {
    justify-content: space-between;
}

.scholarship-certify-student-edit-row .form-input {
    max-height: 30px;
    min-width: 200px;
}

.scholarship-spacer {
    flex: 1;
}

.scholarship-certify-student-edit-row .gender-enclosure {
    display: flex;
    flex-direction: column;
}

    .scholarship-certify-student-edit-row .gender-enclosure span {
        font-weight: bold;
    }

.close-button {
    border: none;
    padding: 0;
    background: none;
    cursor: pointer;
}

    .close-button span {
        color: #3cc6e8;
    }

.studentEditDropdownContainer {
    display: flex;
    flex-direction: column; /* Stack elements vertically */
    align-items: flex-start; /* Align items to the left */
    margin-bottom: 10px; /* Optional: Adds space below the select field */
}

.studentEditDropdownLabel {
    margin-bottom: 5px; /* Optional: Adds space between the label and the select */
    width: 100%; /* Ensures the label takes up the full width */
}


.deceased {
    display: flex;
    align-items: center;
    margin: 38px 20px 0 30px;
}

    .deceased span {
        margin-left: 5px;
    }

.small-top-spacer {
    margin-top: 1px;
}

.scholarship-certify-student-edit-row button {
    margin-right: 20px;
}

.scholarship-certify-student-edit-row .link-button {
    margin-top: 10px;
}

.certify-edit--info {
    color: #3cc6e8;
    font-weight: bold;
    font-size: 0.8em;
}

.link-button {
    background: none !important;
    border: none;
    padding: 0 !important;
    /*optional*/
    font-family: arial, sans-serif;
    /*input has OS specific font-family*/
    color: #123225;
    text-decoration: underline;
    cursor: pointer;
}

.ferpa-form .line-scale > div {
    height: 50px;
    width: 10px;
}

.parent-form-city-state-zip-field {
    margin-left: 20px;
}

.certification-status-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 2px solid #123225;
}
