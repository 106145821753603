.term-payout-edit-row {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    background-color: rgb(238, 240, 197);
    margin-bottom: 20px;
    min-height: 140px;
    border-radius: 10px;
}
.term-payout-edit-column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.term-payout-edit-box {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
}

.term-payout-edit-box span:not(.term-readonly-label) {
    font-weight: 700;
    font-size: 12px;
}

.term-payout-edit-box.center {
    align-self: center;
}

.term-payout-edit-box-veritcal-line {
    border-left-style: solid;
    border-left-color: black;
    border-left-width: 1px;
}
.term-payout-edit-box-select {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 10px;
}
.term-payout-edit-box-select select:first-child {
    margin-right: 5px;
}
.term-payout-edit-row-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-right: 5px;
}
.term-payout-edit-row-button button:first-child {
    margin-right: 10px;
    margin-left: 5px;
}
.term-payout-center {
    justify-content: center;
    align-items: center;
}
.term-payout-btn {
    padding: 6px 25px;
    border: none;
    background-color: #123225;
    color: #ffffff;
    font-family: "Open Sans", sans-serif;
    border-radius: 5px;
}

.term-payout-btn-reverse {
    color: #123225 !important;
    background-color: #ffffff !important;
    border-style: solid;
    border-color: #123225;
    border-width: 1px;
}
.term-payout-edit-box-fix-select-width {
    min-width: 200px;
}
