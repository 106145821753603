.cash-crusader {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 10px;
}
.cash-crusader .header1 {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.cash-crusader .header1 span {
    font-weight: bold;
}
.cash-crusader .header2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
}
.cash-crusader .header2 span {
    font-weight: bold;
}
.cash-crusader .spacer {
    flex: 1;
}
