.scholarship-programs-cash-column {
    max-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 20px;
}

.scholarship-programs-cash-column span:first-child {
    font-weight: bold;
}
