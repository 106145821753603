.scholarship-certify-student {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    max-width: 600px;
    background-color: #2c6648;
    border-radius: 15px;
    padding-right: 10px;
    cursor: pointer;
    margin-bottom: 20px;
    margin-right: 10px;
    margin-top: 10px;
    color: #ffffff;
}

.scholarship-certify-student span {
    color: #ffffff;
}

.scholarship-certify-student.selected {
    opacity: 0.75;
}

.scholarship-certify-student .fa-eye {
    color: #123225;
}
.scholarship-certify-student-inner {
    flex: 1;
    padding: 10px;
}

.scholarship-certify-student-inner .name {
    font-size: 25px;
}

.bold {
    font-weight: bold;
}
.maxNotReached{
    display:none;
}
.maxReached{
    color: red !important;
    float: right;
    
}