.recruitment-cycle-item {
    padding: 10px 20px 20px;
    border: solid 1px #123225;
    border-radius: 8px;
    display: inline-block;
    position: relative;
    margin: 0 10px 10px 0;
}

.recruitment-cycle-item__title {
    color: #123225;
    text-transform: none;
    font-size: 15px;
}

.recruitment-cycle-item table {
    border-collapse: collapse;
    font-size: 14px;
}

.recruitment-cycle-item thead {
    font-weight: 600;
}

.recruitment-cycle-item td,
.recruitment-cycle-item th {
    padding: 0 20px 0 10px;
    text-align: left;
}

.recruitment-cycle-item__actions {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
}

.recruitment-cycle-item__actions span {
    color: #bdf2ff;
}

.recruitment-cycle-item__actions > button + button {
    margin-left: 5px;
}

.recruitment-cycle-item__grade-selection {
    font-size: 14px;
    margin-left: 10px;
    margin-bottom: 10px;
}

.recruitment-cycle-item__grade-selection strong {
    margin-right: 5px;
}
