.scholarship-send-payment-payment {
    background-color: #F5E8D1;
    border-radius: 10px;
    padding: 15px 25px;
    display: flex;
    flex-direction: column;
    align-self: flex-start;
}

.scholarship-send-payment-payment .payment-top {
    display: flex;
}

.scholarship-send-payment-payment .payment-top-left {
    display: flex;
    flex-direction: column;
}

.scholarship-send-payment-payment .payment-top-right {
    display: flex;
    margin-left: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
}

.scholarship-send-payment-payment .payment-bottom {
    display: flex;
}

.scholarship-send-payment-payment .payment-bottom > div + div {
    margin-left: 20px;
}

.scholarship-send-payment-payment .payment-total {
    font-weight: 600;
    width: 150px;
    text-align: right;
}

.scholarship-send-payment-payment .payment-field {
    margin-right: 20px;
    position: relative;
}

.scholarship-send-payment-payment .rdt {
    display: flex;
}
