.forgot-user-name {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background-color: #f8f6e3; */
    padding-top: 12px;
    flex: 1 0 0;
}

.forgot-user-name > div {
    flex: 1;
    max-width: 600px;
    display: flex;
    flex-direction: column;

    align-items: center;
}
.forgot-user-name h1 {
    margin-bottom: 20px;
}
.forgot-user-name input {
    height: 30px;
}
.forgot-user-name button {
    margin-top: 30px;
}
.forgot-user-name-buttons {
    min-width: 300px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}
.forgot-user-name-buttons a {
    margin-top: 30px;
}
