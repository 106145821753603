.login-new-account {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: stretch;
}
.login-new-account-right {
    width: 400px;
    margin-right: 50px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
}
.login-new-account-buttons {
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.login-new-account-button {
    min-width: 300px;
    min-height: 60px;
    margin: 0 20px 10px 0;
}
.login-new-account-instructions {
    padding: 5px;
}
.login-password-requirements {
    font-size: 0.9em;
    padding-bottom: 1em;
}
.login-username-mobile-phone {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}
.login-user-name-create {
    margin-right: 5px;
}
.login-user-name-mobile-phone {
    max-width: 155px;
}
.login-user-name-requirements {
    font-size: 0.9em;
    margin-bottom: 5px;
}
.terms-wrapper {
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.terms {
    text-align: center;
}
.terms input {
    margin-right: 10px;
}

@media only screen and (max-width: 600px) {
    .login-new-account-right {
        width: unset;
        margin-right: 0;
    }
    .login-new-account {
        padding: 0 10px;
    }
}
