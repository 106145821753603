.scholarship-reports-summary .donated {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-width: 550px;
  max-width: 550px;
  min-height: 170px;
  border-style: solid;
  border-color: black;
  border-width: 1px;
  padding: 10px;
  cursor: pointer;
}
.scholarship-reports-summary .donated-active {
  background-color: #123225;
  color: white;
}
.scholarship-reports-summary .donated-active span {
  color: white;
}

.scholarship-reports-summary .donated .rowHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: stretch;
  margin-bottom: 5px;
}

.scholarship-reports-summary .donated .rowHeader .spacer {
  flex: 1;
}
.scholarship-reports-summary .donated .row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: stretch;
  margin-top: 10px;
}

.scholarship-reports-summary .donated .row .spacer {
  flex: 1;
}
.scholarship-reports-summary .donated .row-spacer {
  min-height: 20px;
  max-height: 20px;
}
.scholarship-reports-summary .donated .row span:nth-child(1) {
  min-width: 150px;
  max-width: 150px;
}
.scholarship-reports-summary .donated .row span:nth-child(2) {
  min-width: 100px;
  max-width: 100px;
  text-align: right;
}
.scholarship-reports-summary .donated .row span:nth-child(3) {
  min-width: 150px;
  max-width: 150px;
  text-align: right;
}
.scholarship-reports-summary .donated .row span:nth-child(4) {
  min-width: 130px;
  max-width: 130px;
  text-align: right;
}
.scholarship-reports-summary .donated .line {
  min-height: 2px;
  max-height: 2px;
  background-color: #F5E8D1;
  margin-top: 5px;
}
