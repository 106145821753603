.status-indicator {
  margin-top:10px;
  padding: 6px 10px;
  font-size: 1em;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 10px;
  color: #e2a331;
}

.status-indicator-value {
  margin-top: 15px;
  padding: 3px 8px;
  color: white;
  border-radius: 5px;
}

.status-indicator--not-submitted {
  background-color: rgba(155,155,155,.90);
}

.status-indicator--resubmit {
  background-color: rgba(155,155,155,.90);
}

.status-indicator--submitted {
  background-color: rgba(18,50,37,.90);
}

.status-indicator--complete {
  background-color: rgba(89,9,155,.90);
}

.status-indicator--accepted {
  background-color: rgba(93,169,4,.90);
}

.status-indicator--rejected {
  background-color: rgba(125,75,30,.90);
}

.status-indicator--lunch-pending {
  background-color: rgba(248,117,5,.90);
}

.status-indicator--applicant-rejected {
  background-color: rgba(164,2,22,.90);
}

.status-indicator--virtual-orientation {
  background-color: rgba(0,35,89,.90);
}
