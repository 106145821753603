.scrollable {
    overflow: auto;
    max-height: 430px;
}
.results-wrapper {
    margin-top: 10px;
}
.row {
    display: flex;
    border-bottom: 1px solid #afafaf;
    padding: 5px 10px;
}
.row.crusader-header {
    font-weight: 600;
    background-color: #123225;
    color: white !important;
}
.row.error span {
    color: #ff0000;
}
.col {
    width: 200px;
}
.span {
	color: #123225;
}

.button-container {
    display: flex;
    /* justify-content: space-between; */
    margin-top: 10px;
}
.col.ssn {
    width: 125px;
}
.col.crusaderid {
    width: 125px;
}
.col.ferpa {
    width: 125px;
}
.col.mobilephone {
    width: 125px;
}
.btn-small {
    font-size: 14px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    align-items: center;
}
.btn-icon {
    color: #ffffff;
    margin-right: 10px;
}
.action-container {
    margin-top: 10px;
}
.rmodel {
    position: absolute;
    top: 20%;
    bottom: 20%;
    left: 20%;
    right: 20%;
    background-color: #ffffff;
    border-radius: 5px;
    padding: 20px;
    border: 1px solid #cfcfcf;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}
.rmodel:focus {
    outline: none;
}
.rmodel h1 {
    align-self: center;
    margin-top: 15px;
}

.close-button {
    color: #123225;
    border: none;
    background-color: #ffffff;
    position: absolute;
    right: 20px;
    top: 15px;
}

.input-textarea {
    margin-top: 10px;
    align-self: stretch;
    height: 200px;
    font-size: 16px;
    font-family: "Open Sans", sans-serif;
    resize: none;
    padding: 10px;
}

.crusaders .button-container {
    display: flex;
}

.crusaders .button-container .ml {
    margin-left: 10px;
}

.crusaders .sweet-alert .btn.disabled {
    background-color: gray;
}
