.crusader-list {
    border: solid 1px #123225;
}

.crusader-list .crusader-list-header {
    background-color: #F5E8D1;
    padding: 10px;
    color: #123225;
    font-weight: bold;
    border-top: solid 3px #123225;
    border-bottom: solid 1px #123225;
}

.crusader-list .crusader-list-table {
    padding: 10px;
}

.crusader-list .crusader-list-title {
    margin-left: 40px;
}

.crusader-list .crusader-list-stats {
    font-size: 0.9em;
    font-weight: normal;
    color: black;
}

.crusader-list .crusader-list-stats .gender-stats,
.crusader-list .crusader-list-stats .hispanic-stats,
.crusader-list .crusader-list-stats .race-stats-2,
.crusader-list .crusader-list-stats .race-stats {
    margin-right: 20px;
}

.crusader-list .crusader-list-stats .race-stats {
    flex: 1;
}

.crusader-list .crusader-list-stats .hispanic-stats,
.crusader-list .crusader-list-stats .grade-stats,
.crusader-list .crusader-list-stats .race-stats {
    border-left: 1px solid #123225;
    padding-left: 20px;
}

.quota-stats ul li span {
    font-weight: 700;
}
