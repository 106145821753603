.document-section {
    display: flex;
    flex-direction: column;
}

.document-section > div:first-child {
    display: flex;
    justify-content: space-between;
}

.document-section > div:nth-child(2) {
    height: 74px;
    margin-top: 10px;
}

.document-section .documents-right {
    margin-right: 10px;
    min-width: 400px;
    display: flex;
    align-items: flex-start;
}

.document-section .documents-right > button {
    margin-right: 20px;
}

.document-section .document-description {
    line-height: 2em;
}

.document-section .document-description .sub-item::before {
    content: "- ";
}

.document-section .image-container {
    display: flex;
    flex-wrap: wrap;
}

.document-section .document {
    display: flex;
    flex-direction: column;
    width: 55px;
    margin: 0 10px;
    justify-content: space-between;
}

.document-section .document > div {
    cursor: pointer;
    text-align: center;
}

.document-section .document > span {
    font-size: 14px;
    text-decoration: underline;
}

.document-section .document > span:hover {
    cursor: pointer;
    opacity: 0.75;
}

.document-section .document span.fa {
    font-size: 50px;
    color: #3cc6e8;
}

.document-section .document-table {
    display: flex;
    flex-wrap: wrap;
}

.document-section .document-cell-1 {
    width: 40%;
}

.document-section .document-cell-2 {
    width: 10%;
}

.document-section .document-cell-3 {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 40%;
}

.document-section .document-cell-4 {
    width: 10%;
}

.document-cell-pending {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.preview_image {
    width: 100%;
}

.preview__container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 600px) {
    .document-section .documents-right {
        min-width: unset;
    }
    .document-section > div:first-child {
        flex-wrap: wrap;
    }
}
