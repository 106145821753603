.reject-applicant-modal .button-container {
    border-top: 1px solid #123225;
    margin-top: 20px;
    padding-top: 20px;
}

.reject-applicant-modal .link-button {
    margin-left: 10px;
}

.reject-applicant-modal textarea.form-input {
    width: 100%;
    height: auto;
}
