.student {
    display: flex;
    justify-content: space-between;
}

.student .content-area {
    flex: 1 1 0%;
}

.student .row {
    display: flex;
}

.student .gender-row {
    display: flex;
}

.student .gender-row > div + div {
    margin-left: 20px;
}

.student .row > div {
    flex: 0 0 50%;
    max-width: 50%;
}

.student .row > div:nth-child(2) > div {
    padding-left: 70px;
}

/* .student .help-area {
    align-self: flex-start;
    margin-left: 40px;
    width: 325px;
    background-color: #F5E8D1;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    font-size: 14px;
}

.student .help-area > div {
    padding: 20px;
} */

.student .column {
    display: flex;
    flex-direction: column;
}

.student .row > .column input[type="radio"],
.student .row > .column input[type="checkbox"] {
    margin-bottom: 15px;
}

.student .hispanic {
    flex-direction: column;
    align-items: flex-start;
}

.student.button-container {
    margin-top: 5px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
}

.student .left-buttons > .link-button {
    margin-left: 20px;
}

.student .date-picker {
    margin-bottom: 10px;
}

.dropdown-date-picker {
    margin-bottom: 10px;
}

.dropdown-date-picker select {
    margin-right: 5px;
}

.student .requirement-container {
    color: #e03c31;
    font-weight: bold;
}

.student .document-section {
    margin-bottom: 10px;
}

.student .link-button {
    color: #123225;
    border: none;
    text-decoration: underline;
    text-transform: initial;
    font-size: 14px;
}

.student .student-selector {
    margin-bottom: 20px;
}

.student .citizenship-label {
    font-weight: bold;
}

.student input[type="checkbox"] {
    margin-right: 10px;
}

.two-more-races input {
    margin-right: 10px;
}
