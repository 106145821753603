.school-list .title-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 5px;
}

.school-list .title-container > div:nth-child(1) {
    margin-left: 15px;
    font-weight: bold;
    font-size: 14px;
}

.school-list .title-container > div:nth-child(2) {
    margin-right: 10px;
}

.school-list .title-container button.btn {
    font-size: 0.8em;
}

.school-list .title-container button.inactive {
    border: 1px solid #123225;
    background-color: white;
    color: #123225;
    padding: 5px 24px;
}

.school-list .list-container {
    border: solid 1px #123225;
    height: 270px;
    overflow-y: auto;
}

.school-list .list-container ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.school-list .list-container ul li {
    font-weight: bold;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.school-list .list-container ul li:hover,
.school-list .list-container ul li.selected {
    background-color: #F5E8D1;
}

.school-list .list-container ul li a {
    padding: 18px;
}

.school-list .list-container input {
    width: 65px;
    margin-bottom: 0;
    font-size: 14px;
    text-align: center;
    padding-left: 0;
}

.school-list .list-container .list-quota {
    padding: 10px;
}
