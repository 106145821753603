.scholarship-certify-total-counts-container {
  margin-top: 20px;
  margin-right: 10px;
  min-width: 200px;
  display: flex;
  flex-direction: row;
  border-top-color: #123225;
  border-top-width: 1px;
  border-top-style: solid;
  border-bottom-color: #123225;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-left-color: #123225;
  border-left-width: 1px;
  border-left-style: solid;
}

.scholarship-certify-total-counts-subcontainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  border-right-color: #123225;
  border-right-width: 1px;
  border-right-style: solid;
  padding: 10px;
}

.scholarship-certify-total-row {
  display: flex;
  flex-direction: row;
}
.scholarship-certify-total-row-space {
  justify-content: space-between;
  margin-top: 20px;
}
.scholarship-certify-total-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.scholarship-certify-total-column > span:first-child {
  font-weight: bold;
}
.scholarship-certify-total-counts-subcontainer
  > .scholarship-certify-total-row
  span {
  font-weight: bold;
  white-space: pre;
}
.scholarship-certify-total-counts-subcontainer > span {
  font-weight: bold;
  white-space: pre;
}

.scholarship-certify-total-counts-container .term-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  border-right: 1px solid #123225;
  padding: 10px;
}

/* .scholarship-certify-total-counts-container .term-container:last-child {
  border-right: 3px solid #123225;
  margin-right: 0;
} */

.scholarship-certify-total-counts-container .term-container .term {
  font-weight: 800;
}

.scholarship-certify-total-counts-container .term-container span.title {
  font-style: italic;
  font-size: 14px;
  margin-right: 10px;
}

.scholarship-certify-total-counts-container .term-container span.value {
  font-weight: 600;
  text-align: right;
}

.scholarship-certify-total-counts-container .term-container .breakdown,
.scholarship-certify-total-counts-container .term-container .donation {
  display: flex;
  justify-content: space-between;
}

.scholarship-certify-total-counts-subcontainer .levels {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-evenly;
}

.scholarship-certify-total-counts-subcontainer .levels .level {
  display: flex;
  flex-direction: column;
}

.scholarship-certify-total-counts-subcontainer .levels .level span.level-title {
  /* font-style: italic;
  font-size: 16px; */
  font-weight: 600;
}

.scholarship-certify-total-counts-subcontainer .levels .level span.value {
  font-weight: 800;
}
