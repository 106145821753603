.term-payout-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow-y: auto;
}
.term-payout-header {
    display: flex;
    flex-direction: column;
    min-width: 520px;
}
.term-payout-header-sub {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}
.term-payout-header-sub span {
    font-weight: bold;
}
.term-payout-entry-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 20px;
}
.term-payout-readonly-row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 5px;
}
.term-readonly-label {
    font-weight: bold;
    margin-right: 10px;
}
.term-readonly-entry {
    min-width: 300px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.term-readonly-label.max {
    max-width: 225px;
}
.term-readonly-label.second {
    margin-left: 10px;
}
