.student-selector {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.student-selector.mobile {
    display: flex;
    justify-content: unset;
    flex-direction: column;
    flex-wrap: unset;
}

.student-selector .student-button {
    border: none;
    background-color: #2C6648;
    color: #3cc6e8;
    padding: 5px 20px 5px 20px;
    font-size: 16px;
    display: flex;
    cursor: pointer;
    border-radius: 10px;
    margin: 0 5px 5px 0;
}

.student-selector .student-button > div.info {
    display: flex;
    flex-direction: column;
}

.student-selector .student-button > div:nth-child(2) {
    align-self: center;
    margin-left: 20px;
}

.student-selector .student-button > div:nth-child(2) > span {
    color: white;
}

.student-selector div > .student-button > div:nth-child(2) > span.complete {
    color: #3cc6e8;
}

.student-selector div.selected > .student-button > div:nth-child(2) > span.complete {
    color: white;
}

.student-selector div > .student-button:hover {
    opacity: 0.75;
}

.student-selector div.selected > .student-button {
    background-color: #3cc6e8;
    color: white;
}

.student-selector .student-button > div.info > div:nth-child(1) {
    font-size: 20px;
    font-weight: bold;
}
