.scholarship-reports-summary-search {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 400px;
}
.scholarship-reports-summary-search > button {
  border: none;
  cursor: pointer;
  background-color: #123225;
  color: white;
  min-width: 60px;
  padding: 10px 40px;
  margin-bottom: 20px;
}

.scholarship-reports-summary-row {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  margin-bottom: 20px;
}

.scholarship-reports-summary-row input {
  min-width: 200px;
  min-height: 29px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.scholarship-reports-summary-row input[type="text"] {
  border-right: none;
}

.scholarship-reports-summary-row button {
  border: none;
  cursor: pointer;
  background-color: #123225;
  margin-right: 3px;
  padding: 5px 10px;
  border-top: 1px solid #afafaf;
  border-right: 1px solid #afafaf;
  border-bottom: 1px solid #afafaf;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.scholarship-reports-summary-row span.fa {
  color: white;
}
