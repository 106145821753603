.scholarship-recieve-refund-payment {
  background-color: #F5E8D1;
  border-radius: 10px;
  padding: 15px 25px;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
}

.scholarship-recieve-refund-payment .payment-top {
  display: flex;
}

.scholarship-recieve-refund-payment .payment-top-left {
  display: flex;
  flex-direction: column;
}

.scholarship-recieve-refund-payment .payment-top-right {
  display: flex;
  margin-left: 20px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.scholarship-recieve-refund-payment .payment-bottom {
  display: flex;
}

.scholarship-recieve-refund-payment .payment-bottom > div + div {
  margin-left: 20px;
}

.scholarship-recieve-refund-payment .payment-total {
  font-weight: 600;
  text-align: right;
}

.scholarship-recieve-refund-payment .payment-field {
  margin-right: 20px;
  position: relative;
}

.scholarship-recieve-refund-payment .rdt {
  display: flex;
}
