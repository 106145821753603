.admissions-event-list {
    font-size: 18px;
}

.admissions-event-list .icon-button > span {
    font-size: 27px;
}

.admissions-event-list .btn {
    font-size: 12px;
}

.admissions-event-list .last-sent {
    font-style: italic;
    font-size: 13px;
}
