.footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 75px;
    background-color: #123225;
    color: white;
    padding: 10px 40px;
    display: flex;
    align-items: center;
}
.footer span {
    font-size: 0.7em;
    color: white;
}
.footer-group {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.footer-copyright {
    display: flex;
    flex-direction: row;
}

@media only screen and (max-width: 600px) {
    .footer {
        display: none;
    }
}
