.household-crusader-container {
    height: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: lightgray;
    margin-bottom: 20px;
    padding-left: 10px;
}
.household-crusader-column-start {
    flex: 1 0 0%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.household-crusader-column-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 20px;
    cursor: -webkit-grab;
    cursor: grab;
    padding: 10px;
}

.household-crusader-column-button:disabled:hover {
    cursor: not-allowed;
}

.household-crusader-column-button:disabled > span {
    color: gray;
}
