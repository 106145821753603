.staff-application-header {
    height: 58px;
    min-height: 30px;
    background-color: #123225;
    color: #123225;
    display: flex;
    align-items: center;
    overflow: hidden;
    flex-wrap: wrap;
}

.staff-application-header .staff-application-header-close {
    margin-left: 10px;
    font-size: 16px;
    display: flex;
    align-items: center;
}

.staff-application-header .staff-application-header-close > button > span {
    color: #ffffff;
}

.staff-application-header .staff-application-header-close {
    margin-left: 10px;
    color: #123225;
    font-size: 16px;
    display: flex;
    align-items: center;
}

.staff-application-header .staff-application-header-close button {
    border: none;
    padding: 0;
    background: none;
    cursor: pointer;
}

.app-header-student {
    margin-right: 25px;
    white-space: nowrap;
    height: 58px;
    display: flex;
    align-items: center;
}

.app-header-student__name {
    color: white;
    font-weight: bold;
    margin-left: 40px;
    font-size: 20px;
}

.app-header-student__details {
    color: white;
    margin-left: 15px;
    font-size: 15px;
}
