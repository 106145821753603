/* .scholarship-student-search {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.scholarship-student-search .search-button {
  padding: 5px 10px;
}

.scholarship-student-search-results {
  max-height: 300px;
  min-width: 850px;
  overflow-y: auto;
  max-width: 870px;
  border: solid 1px #ddd;
}

.scholarship-student-search-results li {
  padding: 5px 5px;
}

.scholarship-student-search-results li:nth-child(odd) {
  background-color: #efefef;
}

.scholarship-student-search-results .div-span-long {
  display: inline-block;
  min-width: 300px;
  width: 300px;
  text-align: left;
}
.scholarship-student-search-results .div-span-short {
  display: inline-block;
  min-width: 115px;
  width: 115px;
  text-align: left;
}
.scholarship-student-search-results ul {
  list-style: none;
}

.scholarship-student-search-results ul li:hover .link-button {
  font-weight: bold;
}
*/
.scholarship-student-search-row {
  min-height: 45px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
/*
.scholarship-student-search-row input {
  min-width: 400px;
  min-height: 29px;
  margin-right: 3px;
}
.scholarship-student-search-row .search-button {
  border: none;
  cursor: pointer;
  background-color: #123225;
  margin-right: 3px;
  padding: 7px 10px;
}*/
.scholarship-student-search-row span.fa {
  color: white;
}

.scholarship-student-search-row span.fa-cloud-download,
.scholarship-student-search-row span.fa-file-archive-o {
  margin-right: 10px;
}

.btn.export-btn {
  margin-right: 20px;
  display: flex;
  align-items: center;
}
.input-with-icon.date {
  height: 25px;
}
