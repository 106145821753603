.scholarship-programs-allocated {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 10px;
    border-right-style: solid;
    border-right-color: black;
    border-right-width: 1px;
}
.scholarship-programs-allocated span {
    margin-bottom: 10px;
}
.scholarship-programs-allocated input {
    margin-bottom: 10px;
    text-align: right;
}
.scholarship-programs-allocated-name {
    font-weight: bold;
}
