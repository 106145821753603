.documents {
    display: flex;
    align-items: flex-start;
}

.documents > div:nth-child(1) {
    flex: 1 1 0%;
}

.documents div.document-section + div.document-section {
    margin-top: 20px;
}
