.admissions-event-form .admissions-event-form-container {
    margin-top: 5px;
    background-color: #F5E8D1;
    padding: 10px;
    width: 400px;
}

.admissions-event-form .row {
    display: flex;
}

.admissions-event-form .title {
    font-weight: bold;
}

.admissions-event-form .row > div {
    flex: 1 1 0;
}

.admissions-event-form .date-container input {
    width: 50%;
}

.admissions-event-form .time-range-container {
    display: flex;
}

.admissions-event-form .sep {
    text-align: center;
    margin-top: 5px;
    margin-right: 5px;
    font-weight: bold;
    width: 20px;
}

.admissions-event-form .time-input input {
    width: 75px;
}

.admissions-event-form .hq-container {
    margin: 30px 0 10px;
}

.admissions-event-form .form-input {
    width: 100%;
    height: 35px;
    max-height: 35px;
    box-sizing: border-box;
}

.admissions-event-form .link-button {
    color: #123225;
    border: none;
    text-decoration: underline;
    text-transform: initial;
    font-size: 14px;
    margin-left: 20px;
}

.admissions-event-form textarea {
    box-sizing: border-box;
    width: 100%;
    height: 100px;
}

.admissions-event-form .notes-info {
    font-style: italic;
    margin-bottom: 5px;
    font-size: 13px;
}
