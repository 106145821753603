.institution-search {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    z-index: 99999999;
}
.institution-search-results {
    min-width: 400px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    background-color: lightgrey;
}
.institution-search-results ul {
    list-style: none;
}

.institution-search-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.institution-search-row input {
    min-width: 400px;
    min-height: 29px;
    margin-right: 3px;
}
.institution-search-row .search-button {
    border: none;
    cursor: pointer;
    background-color: #123225;
    margin-right: 3px;
    padding: 7px 10px;
}
.institution-search-row span.fa {
    color: white;
}
